.remote-access-modal .ant-modal-body {
    text-align: center;
}

.remote-access-modal .remote-access-state {
    font-size: 36px;
    text-transform: uppercase;
    display: block;
    margin-bottom: 15px;
}

.remote-access-modal .remote-access-action {
    margin-top: 30px;
}

.remote-access-modal .remote-access-hint {
    font-size: 12px;
    color: #918f8f;
    padding: 10px 0;
}
