.content-background {
    padding: 8px;
    background: #fff;
}

.card-style {
    border-radius: 8px;
    color: rgba(0, 0, 0, 0.88);
    border: 1px solid #ebedf0;
    background: #ffffff;
}

a:not([disabled]) {
    color: var(--ant-color-primary);
    transition: opacity 0.3s ease-in-out;
}

a:not([disabled]):hover {
    color: var(--ant-color-primary);
    opacity: 0.7;
}

.ant-typography a:not([disabled]) {
    color: var(--ant-color-primary);
    transition: opacity 0.3s ease-in-out;
}

.ant-typography a:not([disabled]):hover {
    color: var(--ant-color-primary);
    opacity: 0.7;
}

.ant-typography a:not([disabled]):focus {
    color: var(--ant-color-primary);
    opacity: 0.7;
}

.ant-typography a:not([disabled]):active {
    color: var(--ant-color-primary);
    opacity: 0.7;
}

.content-background {
    padding: 8px;
    background: #fff;
}

.ant-btn-link:not([disabled]) {
    color: var(--ant-color-primary);
    transition: opacity 0.3s ease-in-out;
}

.ant-btn-link:not([disabled]):hover {
    color: var(--ant-color-primary) !important;
    opacity: 0.7;
}

.ant-btn-link:not([disabled]):focus {
    color: var(--ant-color-primary);
    opacity: 0.7;
}

.ant-btn-link:not([disabled]):active {
    color: var(--ant-color-primary);
    opacity: 0.7;
}

.ant-table-wrapper .ant-table-filter-trigger {
    color: lightgray;
}

.ant-table-wrapper .ant-table-column-sorter {
    color: lightgray;
}

.ant-modal .ant-modal-close {
    position: absolute;
    top: 17px;
    inset-inline-end: 17px;
    z-index: 1010;
    padding: 0;
    color: rgba(0, 0, 0, 0.45);
    font-weight: 600;
    line-height: 1;
    text-decoration: none;
    background: transparent;
    border-radius: 4px;
    width: 22px;
    height: 22px;
    border: 0;
    outline: 0;
    cursor: pointer;
    transition:
        color 0.2s,
        background-color 0.2s;
}

.ant-modal .ant-modal-close:hover {
    color: rgba(0, 0, 0, 0.88);
    background-color: rgba(0, 0, 0, 0.06);
    text-decoration: none;
}

.ant-modal-confirm .ant-modal-confirm-body {
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: flex-start;
}

.ant-table-wrapper .ant-table {
    border-radius: 8px;
}

.ant-table-wrapper .ant-table-tbody > tr:last-child > td:first-child {
    border-bottom-left-radius: 8px;
}

.ant-table-wrapper .ant-table-tbody > tr:last-child > td:last-child {
    border-bottom-right-radius: 8px;
}

.ant-table-wrapper .ant-table-tbody > tr:last-child > td.ant-table-cell-row-hover:last-child {
    border-end-end-radius: 8px;
}

.ant-table-wrapper .ant-table-tbody > tr:last-child > td.ant-table-cell-row-hover:first-child {
    border-end-start-radius: 8px;
}

.ant-dropdown .ant-table-filter-dropdown {
    max-width: 320px;
}

.ant-tag.ant-tag-gold {
    color: #fac42a;
    border-color: #fac42a;
    background: rgba(250, 196, 42, 0.1);
}

.ellipsis {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.resizable-handle {
    user-select: none;
    cursor: col-resize;
    z-index: 1;
    position: absolute !important;
    top: -10px !important;
    right: 7px !important;
    height: 58px !important;
    width: 2px !important;
}

.resizable-handle:hover {
    cursor: col-resize;
    border-bottom: 2px solid #cccccc;
    border-right: 2px solid #cccccc;
}

.ant-table-header .ant-table-thead .ant-table-cell {
    overflow: hidden;
    overflow-wrap: unset;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.ant-table-header .ant-table-thead .ant-table-cell .ant-table-column-sorters .ant-table-column-title {
    overflow: hidden;
    text-overflow: ellipsis;
}
