.weather-forecast-day-card {
    text-align: center;
    padding: 0.5rem 0;
}

.weather-forecast-day-card h2 {
    color: #444444;
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 1.25;
}

.weather-forecast-day-card h1 {
    color: #444444;
    font-size: 2.5rem;
    font-weight: 300;
    line-height: 1.125;
    padding-left: 15px;
}

.weather-forecast-day-card .icon-wrap {
    font-size: 60px;
}

.weather-forecast-day-card .description {
    min-height: 45px;
}

.weather-forecast-day-card .dr-chance {
    height: 25px;
}

.weather-forecast-day-card .dr-chance-0 {
    background-color: #888787;
}

.weather-forecast-day-card .dr-chance-1 {
    background-color: #26ad5f;
}

.weather-forecast-day-card .dr-chance-2 {
    background-color: #fac42a;
}

.weather-forecast-day-card .dr-chance-3 {
    background-color: #fa675c;
}

.weather-forecast-legend {
    padding: 10px 10px 24px 0;
}
