.peak-load-forecast-calendar {
}

.peak-load-forecast-calendar td:has(.week) {
    background-color: #f2f3f3;
}

.peak-load-forecast-calendar--day.today {
    background-color: #fa675c;
    color: #ffffff;
    border-radius: 4px;
    border: none;
    font-weight: 700;
}

.peak-load-forecast-calendar--day.high:not(.today) {
    background-color: #ffccd6;
    border-radius: 50%;
}

.peak-load-forecast-calendar--day.medium:not(.today) {
    background-color: #ffe8cc;
    border-radius: 50%;
}

.peak-load-forecast-calendar .ant-picker-cell-selected {
    background: transparent;
    background-color: transparent;
    border: 1px solid #d6dbdb;
}

.peak-load-forecast-calendar-cell-inner {
    position: relative;
    z-index: 2;
    display: inline-block;
    min-width: 24px;
    height: 24px;
    line-height: 24px;
    border-radius: 4px;
}

.peak-load-forecast-calendar.ant-picker-calendar .ant-picker-panel {
    border-top: none;
}
