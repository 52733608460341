.status-confirmed {
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background-color: #52c41a;
    margin-right: 8px;
}

.status-terminated {
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background-color: #d9d9d9;
    margin-right: 8px;
}

.status-new {
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background-color: #52affa;
    margin-right: 8px;
}

.status-inactive {
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background-color: #faad14;
    margin-right: 8px;
}

.status-indicator {
    display: flex;
    align-items: center;
}
