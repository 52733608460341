.peak-event-index-week-view {
    width: 100%;
    min-height: 182px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: stretch;
    padding: 0;
}

/* .layout-content:has(.peak-event-index-week-view) {
    background-color: #fff;
} */

.peak-event-index-week-view .peak-event-index-day {
    font-family: 'Raleway';
    flex-grow: 1;
    text-align: center;
    padding: 20px 20px 10px 20px;
    cursor: pointer;
}

.peak-event-index-week-view .peak-event-index-day.disabled {
    cursor: not-allowed;
}

.peak-event-index-week-view .peak-event-index-day:not(:first-child) {
    border-left: 1px solid #d6dbdb;
}

.peak-event-index-week-view .peak-event-index-day--index {
    font-size: 40px;
    line-height: 47px;
    color: #687575;
}

.peak-event-index-week-view .peak-event-index-day.high .peak-event-index-day--index {
    color: #fa675c;
}

.peak-event-index-week-view .peak-event-index-day.medium .peak-event-index-day--index {
    color: #ff8c00;
}

.peak-event-index-week-view .peak-event-index-day.selected {
    background-color: #f7f8f8;
    border-radius: 10px 10px 0 0;
    border-left: 1px solid #f2f3f3;
    border-right: 1px solid #f2f3f3;
}

.peak-event-index-week-view .peak-event-index-day.selected + .peak-event-index-day {
    border: 0;
}

.peak-event-index-week-view .peak-event-index-day--date {
    font-size: 12px;
    line-height: 24px;
    font-weight: 400;
    padding: 10px 0;
}

.peak-event-index-week-view .peak-event-index-day.today .peak-event-index-day--date .peak-event-index-day-num {
    display: inline-block;
    background-color: #fa675c;
    padding: 0 4px;
    border-radius: 4px;
    color: #ffffff;
    font-weight: 700;
    line-height: 24px;
}

.peak-event-index-week-view .peak-event-index-day--hour {
    background-color: #ffffff;
    color: #a0abab;
    width: 90%;
    margin: 10px auto;
    padding: 8px;
    border: 1px solid #bbc3c3;
}

.peak-event-index-week-view .peak-event-index-day.high .peak-event-index-day--hour {
    background-color: #fa675c;
    border: 1px solid #fa675c;
    color: #ffffff;
}

.peak-event-index-week-view .peak-event-index-day.medium .peak-event-index-day--hour {
    background-color: #ff8c00;
    border: 1px solid #ff8c00;
    color: #ffffff;
}
