.peak-load-forecast-base-chart {
    height: 350px;
    background-color: #ffffff;
    margin-bottom: 35px;
    padding-bottom: 20px;
    box-sizing: content-box;
    position: relative;
}

.peak-load-forecast-base-chart .chart-timezone {
    position: absolute;
    left: 20px;
    bottom: 21px;
    font-size: 12px;
    color: #697475;
}

.peak-load-chart {
    height: 550px;
}

.peak-load-forecast-chart-title {
    font-size: 20px;
    font-weight: 400;
    color: #545f5e;
    padding: 20px 70px 20px 80px;
}
