.saved-item-card {
    border-radius: 0;
    border-bottom: 1px solid #d6dbdb;
}
.saved-item-card :hover {
    cursor: pointer;
}
.saved-item-card.selected {
    border-left: 6px solid var(--ant-color-primary); /* Highlight selected filter */
    background-color: #e6f7ff; /* Light blue background for selected filter */
}
.saved-item-card > .ant-card-body {
    background-color: #f2f3f3;
    border-radius: 0;
    padding: 20px 10px;
}
.saved-item-card > .ant-card-body > .ant-typography-secondary {
    font-size: 12px;
    font-weight: 400;
    line-height: 14.06px;
    letter-spacing: 0.01em;
}
.saved-item-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.saved-item-footer {
    display: flex;
    justify-content: space-between;
    margin-top: 8px;
}
.saved-item-footer > .ant-typography-secondary {
    font-size: 12px;
    font-weight: 400;
}
.saved-item-footer > .saved-item-footer-results {
    color: #4a7088;
    font-size: 12px;
    font-weight: 400;
}
.saved-item-header .saved-item-icon {
    cursor: pointer;
    padding: 0 2px;
    font-size: 18px; /* Increase size */
}
.saved-item-icon-gold {
    cursor: pointer;
    padding: 0 2px;
    font-size: 18px; /* Increase size */
    color: #ffd700; /* Change color to gold */
}
.saved-item-options {
    color: #595959; /* Make darker */
    cursor: pointer;
    font-size: 18px; /* Increase size */
}
.sidebar-options-panels-container > .sidebar-tab > .ant-tabs-nav {
    margin: 0;
    padding: 0 10px;
}

.sidebar-options-panels-container > .sidebar-tab {
    height: calc(100vh - 160px);
    overflow-y: auto;
}
.sidebar-options-panels-container .content-holder {
    background-color: #f2f3f3;
}
