@font-face {
    font-family: 'Raleway';
    src: url('./font/Raleway/static/Raleway-Medium.ttf') format('truetype');
}

/* We do override Raleway font numbers with numbers from Roboto numbers */
@font-face {
    font-family: 'Raleway';
    src: url('./font/Roboto/Roboto-Regular.ttf') format('truetype');
    unicode-range: U+0030-0039; /* numbers from 0-9 */
}

body {
    margin: 0;
    font-family:
        'Raleway',
        -apple-system,
        BlinkMacSystemFont,
        'Segoe UI',
        'Roboto',
        'Oxygen',
        'Ubuntu',
        'Cantarell',
        'Fira Sans',
        'Droid Sans',
        'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.loading-container {
    text-align: center;
}

.grecaptcha-badge {
    visibility: hidden;
}

/** we add this style to all modal footers in order to allow displaying some buttons on the left side */
.ant-modal-footer {
    display: flex;
    justify-content: flex-end;
}
/**
 *  Style added in order to solve a ant design issue with the table show/hide feature of the sidebar grid filter
 *  https://blog.csdn.net/qq_58657388/article/details/135171245
 */
#webpack-dev-server-client-overlay {
    display: none !important;
}
