.app-navigation {
    font-size: 16px;
    margin-left: 24px;
    display: flex;
    flex-grow: 1;
    flex-wrap: wrap;
}

.app-navigation.ant-menu .ant-menu-item,
.app-navigation.ant-menu .ant-menu-submenu {
    margin: 0 !important;
}

.app-navigation .ant-menu-submenu .ant-menu-submenu-title:not(.app-navigation .ant-menu-submenu.ant-menu-overflowed-submenu .ant-menu-submenu-title) {
    padding: 0 20px;
}

.app-navigation.ant-menu-horizontal > .ant-menu-submenu {
    padding: 0;
}

.app-navigation.ant-menu-horizontal > .ant-menu-item::after,
.app-navigation.ant-menu-horizontal > .ant-menu-submenu::after {
    left: 0;
    right: 0;
}

.app-navigation .ant-menu-item-selected,
.app-navigation .ant-menu-item-active:hover,
.app-navigation .ant-menu-submenu:hover,
.app-navigation .ant-menu-submenu.ant-menu-submenu-horizontal.ant-menu-submenu-selected,
.app-navigation .ant-menu-submenu-selected.navigation-item {
    background-color: color-mix(in srgb, var(--ant-color-primary) 10%, transparent);
}

.ant-menu-light.ant-menu-horizontal > .ant-menu-item::after,
.ant-menu-light.ant-menu-horizontal > .ant-menu-submenu::after,
.ant-menu-light > .ant-menu.ant-menu-horizontal > .ant-menu-submenu::after {
    z-index: 2;
}
