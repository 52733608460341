.impact-information-card-divider {
    height: 50px;
}

.impact-information-card-divider:last-child {
    display: none;
}

.impact-information-card {
    min-width: fit-content;
    padding: 0px !important;
    border: none !important;
}
