.dashboard-page {
    padding: 8px 0;
}

.marker-info-window .info-window-title {
    font-size: 14px;
    font-weight: 400;
    padding-right: 24px;
}

.marker-info-window .info-window-subtitle {
    font-size: 14px;
    font-weight: bold;
}

.map-legend {
    padding: 10px 0;
}

/*DRMS-3341 Change marker popup style*/
.dashboard-page div[role='dialog'] .gm-style-iw-chr {
    max-height: 16px;
}

@supports (-moz-appearance: none) {
    .marker-info-window .info-window-title {
        padding-right: 42px;
    }

    .dashboard-page div[role='dialog'] .gm-style-iw-chr button span {
        margin-left: 24px !important;
    }
}
