.sidebar-accordion-panels {
    border-radius: 0;
    background-color: #ffffff;
}

.sidebar-accordion-panels > .ant-collapse-item {
    background-color: #f2f3f3;
}

.sidebar-accordion-panels > .ant-collapse-item > .ant-collapse-header {
    background-color: #ffffff;
    font-size: 14px;
    font-weight: 500;
    padding: 12px 12px;
}

.sidebar-accordion-panels > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box {
    padding: 0;
}

.sidebar-accordion-panels .accordion-panels-filters > .ant-collapse-content[role='tabpanel'] > .ant-collapse-content-box,
.sidebar-accordion-panels .accordion-panels-options > .ant-collapse-content[role='tabpanel'] > .ant-collapse-content-box {
    background-color: #f2f3f3;
    overflow-y: auto;
    max-height: calc(100vh - 254px);
}

.sidebar-accordion-panels .accordion-panels-options {
    border-bottom: 1px solid #d9d9d9 !important;
}
