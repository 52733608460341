.sidebar-grid-filter {
    padding: 10px 0;
    background-color: #f2f3f3;
}
.sidebar-grid-filter .sortable-item {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    cursor: pointer;
}
.sidebar-grid-filter .sortable-item > span {
    margin-right: 5px;
}

.sidebar-grid-filter .sortable-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #f2f3f3;
    padding: 0 10px;
}
.sidebar-grid-filter .sortable-container > div > span {
    margin-right: 5px;
}

.sidebar-grid-filter .custom-checkbox {
    margin-left: 8px;
}

.sidebar-grid-filter .custom-checkbox .ant-checkbox {
    display: none;
}

.sidebar-grid-filter .custom-checkbox .ant-checkbox-checked + span {
    display: flex;
    align-items: center;
    justify-content: center;
}

.sidebar-grid-filter .custom-checkbox span {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    color: #687575;
    background-color: transparent;
    transition: color 0.3s;
}

.sidebar-grid-filter .custom-checkbox span:hover {
    color: #31aca9;
}

.sidebar-grid-filter .grid-reset-btn {
    margin-top: 16px;
    margin-left: 6px;
    display: inline-block;
    cursor: pointer;
    border: none;
    color: #4a7088;
    text-decoration: underline;
}
