.sites-table .enrolled-site-row {
    background: color-mix(in srgb, var(--ant-color-primary) 10%, transparent);
}

.hidden-columns-sites-table {
    display: flex;
    align-items: center;
}
.hidden-columns-sites-table > span {
    color: #4a7088;
    text-decoration: underline;
}
