.peak-load-forecast-customize-view .ant-slider {
    margin: 11px 5px 0 5px;
    width: 90%;
}

.peak-load-forecast-customize-view .ant-tooltip-content .ant-tooltip-inner {
    background-color: transparent;
    box-shadow: none;
    color: #687575;
    line-height: 14px;
    padding: 0;
    margin-top: 0px;
}

.peak-load-forecast-customize-view .ant-tooltip-arrow {
    height: 0;
    display: none;
}

.customize-view--timeframe {
    height: 60px;
}

.customize-view-title {
    color: #424d4d;
    font-weight: 700;
    font-size: 15px;
    margin: 15px 0 20px 0;
}

.customize-view-subtitle {
    color: #424d4d;
    font-weight: 600;
    font-size: 14px;
    margin: 10px 0 10px 0;
}
