.price-response-target-sites-selector.wrapper {
    width: 100%;
}

.price-response-target-sites-selector.radio-group-options-wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    max-height: 240px;
    overflow-y: scroll;
    background-color: rgba(217, 217, 217, 0.27);
    border: 1px solid #d9d9d9;
    width: 100%;
}

.price-response-target-sites-selector.radio-item {
    padding: 6px;
    margin: 0;
    flex: 1 0 32%;
    max-width: 32%;
    background: white;
}

.price-response-target-sites-selector.loader-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 160px;
    width: 100%;
}

.price-response-target-sites-selector .ant-radio-wrapper .ant-radio {
    display: none;
}
