.auth-layout {
    background-color: transparent;
}

.auth-layout .logo {
    display: block;
    width: 100%;
    height: 95px;
    padding: 0 0 25px 0;
}

.auth-layout-content {
    width: 452px;
    margin: 200px auto;
    /* box-shadow: 0px 0px 20px 1px rgb(34 60 80 / 20%); */
    box-shadow:
        0px 0px 1px hsl(0deg 0% 0% / 8%),
        0px 0 2px hsl(0deg 0% 0% / 8%),
        0 4px 4px hsl(0deg 0% 0% / 8%),
        0 8px 8px hsl(0deg 0% 0% / 8%),
        0 16px 16px hsl(0deg 0% 0% / 8%);
    border: 4px solid #fff;
    border-radius: 4px;
    background-color: #fff;
}
