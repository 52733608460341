.reporting-site-selector .site-tag {
    max-width: 240px;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-top: 9px;
}

.reporting-site-selector .ant-select-selection-item {
    background: none;
    border: none;
}
