.information-card {
    display: flex;
    flex-direction: column;
}

.information-card-title {
    display: flex;
    align-items: center;
    font-weight: 500;
    color: #888787;
    justify-content: center;
}
