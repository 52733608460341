.cloud-ven-logs-modal .logs-line-datetime {
    font-size: 12px;
    color: #00000073;
}

.cloud-ven-logs-modal .logs-line {
    font-family: monospace;
}

.cloud-ven-logs-modal .stream-divider {
    display: block;
    text-align: center;
    margin: 0 0 25px 0;
}
