.events-page {
}

.event-calendar-page {
    position: relative;
}

.event-calendar-page .event {
    background-color: #2fadab;
    color: #fff;

    font-size: 12px;
    line-height: 14px;
    font-weight: 400;
}

.event-calendar-page .test-event {
    background-color: #3febe8;
    /*color: #000000 !important;*/
}

/* FIXME: If need black text on test event */
/*.event-calendar-page .test-event > .fc-event-main {*/
/*    color: #000000 !important;*/
/*}*/

.event-calendar-page .event:hover {
    color: #fff;
}

.event-calendar-page .price-response-event {
    background-color: #2771ad;
}

.event-calendar-page .clean-response-event {
    background-color: #26ad5c;
}

.event-calendar-page .pre-event {
    background-color: #fac42a;
}

.event-calendar-page .post-event {
    background-color: #fa675c;
}

.event-calendar-page .secondary-event {
    background-color: #fac42a;
}

.event-calendar-page .coincident-peak-event {
    background-color: #ad4093;
}

.event-calendar-page .canceled-event {
    background-color: #444444;
}

.event-calendar-page .optout-event {
    background-color: #888787;
}

.event-calendar-page .event-form-container {
    margin-top: 16px;
}

.event-calendar-page .event-calendar {
    width: 100%;
}
.event-calendar-page .event-calendar .event-month-content {
    display: flex;
    align-items: center;
    overflow: hidden;
}
.event-history-table th {
    white-space: nowrap;
}

.event-history-table td {
    min-width: 100px;
    max-width: 300px;
}

.event-calendar-page .site-highlight .ant-tag-checkable {
    font-weight: bold;
}
