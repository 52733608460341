/* Styles used for react-fullcalendar */
.event-calendar {
    padding-bottom: 50px;
}

.fc-button:not(:first-child) {
    border-radius: 8px;
}

.fc-button:not(:last-child) {
    border-radius: 8px;
}

.fc-button {
    border-radius: 8px;
}

.fc-button-primary {
    border-radius: 8px;
}

.fc-toolbar-title {
    text-align: center;
}

.fc-hint-button {
    cursor: unset !important;
    border: none !important;
    background: none !important;
    box-shadow: none !important;
    color: darkgrey !important;
    max-width: 700px;
    font-size: 12px;
}

.fc-hint-button:active,
.fc-hint-button:hover {
    outline: none;
}
