.alternative-names-input .add-utility-name {
    color: #2fadab;
    background: transparent;
    border-style: dashed;
    border-color: #2fadab;
    cursor: pointer;
}

.alternative-names-input .tag-input {
    width: 78px;
    margin-right: 8px;
    vertical-align: top;
}
