.green-btn-container .shared-green-btn-subtitle {
    font-size: 18px;
    color: #fff;
}

.green-btn-container {
    justify-content: center;
    padding: 72px 16px;
    background: rgb(41, 112, 185);
    background: linear-gradient(214deg, rgba(41, 112, 185, 1) 9%, rgba(46, 173, 171, 1) 43%);
    margin: 0px !important;
    border-radius: 20px;
    width: 100%;
}

.green-btn-container .green-btn {
    text-align: center;
    width: 230px;
    height: 230px;
    border-radius: 20px;
    background: #fff;
    margin-right: 32px;
    padding: 16px 0 0;
}
.green-btn-container .green-btn-img-wrapper {
    width: inherit;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 8px;
    height: 150px;
}

.green-btn-container .green-btn:last-child {
    margin-right: 0px;
}

.green-btn-container .green-btn img {
    width: 140px;
}

@media all and (max-width: 768px) {
    .green-btn-container {
        justify-content: space-evenly;
    }
    .green-btn-container .green-btn {
        margin-right: 0;
    }
}
