.metric-data-container {
    display: flex;
    justify-content: center;
    align-items: baseline;
}

.metric-value {
    font-weight: 500;
    font-size: 1.875rem;
}

.metric-uom {
    font-size: 0.75rem;
    padding-left: 2px;
}
