.header .app-logo {
    float: left;
    height: 30px;
    margin: 16px 24px 16px 0;
}

.header .app-logo-small {
    display: none;
}

.layout-container {
    height: calc(100vh - 70px);
    overflow: hidden;
}

.sidebar-layout-content {
    padding: 0 30px 0 10px;
    width: 100%;
    min-height: 280px;
    height: calc(100vh - 70px);
    overflow-y: auto;
}

.layout-content {
    padding: 24px 48px 0 48px;
    margin: 0;
    min-height: calc(100vh - 64px);
    border-top: 1px solid #d9d9d9;
    width: 100%;
}

.layout-content .ant-layout-content {
    margin-bottom: 80px;
}

.global-feedback-button {
    position: fixed;
    display: block;
    inset-inline-end: -33px;
    inset-block-end: 48px;
    top: 50%;
    z-index: 99;
    rotate: 270deg;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}

.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.footer {
    font-size: 10px;
    text-align: center;
    left: 0;
    width: 100%;
    background-color: #f1f1f1;
}
.header .user-menu {
    cursor: pointer;
    display: flex;
    align-items: center;
}

.header .user-menu:hover {
    color: var(--ant-color-primary);
}

.header .user-menu .user-name {
    padding: 0 5px;
}

@media all and (max-width: 480px) {
    .user-menu .user-name {
        display: none;
    }

    .user-menu .user-menu-icon {
        padding-left: 5px;
    }

    .header {
        padding: 0 20px;
    }

    .header .full-logo {
        display: none;
    }

    .header .app-logo-small {
        display: block;
        height: 30px;
        margin-right: 5px;
    }

    .layout-content {
        padding: 24px 20px 0 20px;
    }
}
