.price-response-reporting {
    width: 100%;
    max-width: 1600px;
}

.price-response-reporting .main-content-value {
    font-size: 35px;
}

.price-response-reporting .content-value {
    font-size: 30px;
}

.price-response-reporting .unenrolled-value {
    color: #fa675c;
}

.price-response-reporting .main-content-description {
    font-size: 25px;
}

.price-response-reporting .content-description {
    font-size: 20px;
}

.price-response-reporting .content-row {
    display: grid;
    grid-template-columns: 50% 50%;
    grid-gap: 10px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
}

.price-response-reporting .trigger-price-card {
    border: 1px solid #2fadab;
}

.price-response-reporting .trigger-price-card .target-icon {
    width: 20px;
    height: 20px;
    fill: #ffff;
    margin-right: 5px;
}

.price-response-reporting .trigger-price-card .trigger-price {
    margin-top: 0;
}

/* .price-response-reporting .co2-impact-widget .content-value {
    display: flex;
    align-items: center;
    justify-content: center;
} */
