.top-peak-days .ant-checkbox .ant-checkbox-inner {
    background-color: transparent;
    border-color: #a0abab;
}

.top-peak-days .ant-checkbox {
    background-color: transparent !important;
    border: #a0abab !important;
}

.top-peak-days .first-day .ant-checkbox-checked .ant-checkbox-inner {
    background-color: transparent;
    border-color: #4b0082;
}

.top-peak-days .first-day .ant-checkbox-checked {
    background-color: #4b0082 !important;
    border: #4b0082 !important;
}

.top-peak-days .first-day:hover .ant-checkbox .ant-checkbox-inner,
.top-peak-days .first-day:hover .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #4b0082 !important;
    border: #4b0082 !important;
}

.top-peak-days .second-day .ant-checkbox-checked .ant-checkbox-inner {
    background-color: transparent;
    border-color: #48c774;
}

.top-peak-days .second-day .ant-checkbox-checked {
    background-color: #48c774 !important;
    border: #48c774 !important;
}

.top-peak-days .second-day:hover .ant-checkbox .ant-checkbox-inner,
.top-peak-days .second-day:hover .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #48c774 !important;
    border: #48c774 !important;
}

.top-peak-days .third-day .ant-checkbox-checked .ant-checkbox-inner {
    background-color: transparent;
    border-color: #ff8c00;
}

.top-peak-days .third-day .ant-checkbox-checked {
    background-color: #ff8c00 !important;
    border: #ff8c00 !important;
}

.top-peak-days .third-day:hover .ant-checkbox .ant-checkbox-inner,
.top-peak-days .third-day:hover .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #ff8c00 !important;
    border: #ff8c00 !important;
}

.top-peak-days .first-day {
    color: #4b0082;
    font-weight: 400;
    font-size: 14px;
}

.top-peak-days .second-day {
    color: #48c774;
    font-weight: 400;
    font-size: 14px;
}

.top-peak-days .third-day {
    color: #ff8c00;
    font-weight: 400;
    font-size: 14px;
}
