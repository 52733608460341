.ant-layout-content:has(#peak-load-forecast) {
    background-color: #fff;
    width: 100%;
}

#peak-load-forecast .market-zone-selector {
    width: auto;
}

#peak-load-forecast .market-zone-selector > .ant-select-selector {
    font-size: 35px;
    border: none;
    background-color: transparent;
    padding: 0 5px;
}

#peak-load-forecast .locations-selector {
    width: 200px;
    margin: auto 0 auto 15px;
}

#peak-load-forecast .sidebar {
    background-color: #fcfdfd;
    border-top: none;
    border-right: 1px solid #d6dbdb;
}

#peak-load-forecast .content {
    background-color: #f7f8f8;
    padding: 20px;
}
