.sidebarForm {
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
}

.checkSection {
    display: flex;
    flex-direction: column;
    row-gap: 3px;
}

.checkSection .checkbox {
    font-size: 0.8rem;
    font-weight: lighter;
}

.checkSection .description {
    font-size: 0.7rem;
    margin-left: 1.5rem;
}

.update-existing-filter-btn {
    padding-inline: 1rem !important;
    margin-top: 0.3rem;
}

.update-existing-filter-btn :hover {
    cursor: pointer;
}

.update-existing-filter-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-color: #fa962a;
    border-width: 1px;
    border-style: solid;
    padding: 0.5rem;
    column-gap: 20px;
    padding-inline: 1.5rem;
}

.update-existing-filter-icon {
    font-size: 2rem;
    align-self: center;
    color: #fa962a;
}

.update-existing-filter-message {
    padding-top: 20px;
    font-weight: bold;
}
