.energy-usage-reporting.summary {
    width: 100%;
    max-width: 1000px;
}

.energy-usage-reporting .content-value {
    font-size: 1.575rem;
    margin: 0;
}

.energy-usage-reporting .energy-usage-chart {
    overflow: hidden;
    height: 110px;
}

.energy-usage-reporting .energy-usage-chart.full-energy-usage-chart {
    height: auto;
}

.energy-usage-reporting .progress-marker {
    width: 5px;
    display: inline-block;
    height: 20px;
    border-radius: 8px;
}

.energy-usage-reporting .ant-progress-line {
    margin: 0;
}
