.sidebar-filter {
    border-radius: 0;
    background-color: #f2f3f3;
    position: relative;
    border-bottom: 1px solid #d6dbdb;
}

.sidebar-filter > .ant-collapse-item {
    background-color: #f2f3f3;
}

.sidebar-filter > .ant-collapse-item .ant-collapse-content-box {
    padding: 4px 6px;
}

.sidebar-filter .filters-content-container {
    height: 40px;
    overflow-y: auto;
    overflow-x: hidden;
    resize: vertical;
    max-height: 450px;
}

.sidebar-filter .no-resize {
    resize: none;
}

.sidebar-filter > .ant-collapse-item > .ant-collapse-header {
    font-size: 14px;
    font-weight: 400;
}

.sidebar-filter .checkbox-filter-container,
.sidebar-filter .radio-filter-container {
    width: 100%;
}

.sidebar-filter .checkbox-filter-container .ant-checkbox-wrapper,
.sidebar-filter .radio-filter-container .ant-radio-wrapper {
    width: 100%;
    margin-bottom: 15px;
    font-size: 12px;
}

.sidebar-filter .checkbox-filter-container .ant-checkbox-wrapper:last-child,
.sidebar-filter .radio-filter-container .ant-radio-wrapper:last-child {
    margin-bottom: 0;
}

.sidebar-filter .checkbox-filter-container .ant-checkbox-inner,
.sidebar-filter .radio-filter-container .ant-radio-inner {
    height: 16px;
    width: 16px;
}

.sidebar-filter .checkbox-filter-container .ant-checkbox-wrapper:hover .ant-checkbox-inner,
.sidebar-filter .radio-filter-container .ant-radio-wrapper:hover .ant-radio-inner,
.sidebar-filter .checkbox-filter-container .ant-checkbox-inner:hover,
.sidebar-filter .radio-filter-container .ant-radio-inner:hover {
    border-color: #4a7088;
}

.sidebar-filter .checkbox-filter-container .ant-checkbox-inner::after {
    width: 6px;
    height: 10px;
}

.sidebar-filter .checkbox-filter-container .ant-checkbox-wrapper:hover .ant-checkbox-checked:not(.ant-checkbox-disabled) .ant-checkbox-inner,
.sidebar-filter .radio-filter-container .ant-radio-wrapper:hover .ant-radio-checked:not(.ant-radio-disabled) .ant-radio-inner {
    background-color: #4a7088;
}

.sidebar-filter .checkbox-filter-container .ant-checkbox-checked > .ant-checkbox-inner,
.sidebar-filter .radio-filter-container .ant-radio-checked > .ant-radio-inner {
    background-color: #4a7088;
    border-color: #4a7088;
}

.sidebar-filter > .search-filter {
    width: 100%;
    margin-bottom: 10px;
}

.sidebar-filter .ant-input-affix-wrapper:hover,
.sidebar-filter .ant-input-affix-wrapper:focus,
.sidebar-filter .ant-input-affix-wrapper:active,
.sidebar-filter .ant-input-search .ant-input:focus,
.sidebar-filter .ant-btn-default.ant-btn-outlined:not(:disabled):not(.ant-btn-disabled):hover {
    border-color: #4a7088;
    outline-color: #4a7088;
    color: #4a7088 !important;
}

.sidebar-filter > .resizer {
    position: absolute;
    right: 0;
    top: 100%;
    height: 1px;
    width: 100%;
    cursor: ns-resize;
    background: transparent;
    transition: background-color 0.3s;
    z-index: 1;
}

.sidebar-filter-form-item > .ant-form-item-row {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.sidebar-filter-form-item > .ant-form-item-row > .ant-form-item-control {
    flex: none;
}

.sidebar-filter-form-item > .ant-form-item-row > .ant-form-item-label > label {
    font-size: 12px;
    font-weight: 400;
    line-height: 22px;
}

.sidebar-filter > .ant-collapse-item > .ant-collapse-content {
    padding-left: 8px;
}
