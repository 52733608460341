.datetime-popover .datetime-popover-utc,
.datetime-popover .datetime-popover-local,
.datetime-popover .datetime-popover-time-zone {
    font-size: 12px;
}

.datetime-popover .datetime-popover-utc span,
.datetime-popover .datetime-popover-local span,
.datetime-popover .datetime-popover-time-zone span {
    width: 100px;
    display: inline-block;
    font-weight: 500;
}
