.locations-detail-summary {
    padding: 0px 20px;
    margin-bottom: 24px;
}

.locations-detail-address {
    padding: 0px 20px;
}

.drrs-location-modal .dropdowns-row {
    margin-bottom: 24px;
}

.drrs-location-modal .dropdowns-row .mandatory-label::after {
    margin-left: 4px;
}

.drrs-location-modal .dropdowns-row .ant-select {
    width: 100%;
}

.status-circle {
    display: inline-block;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    margin-right: 8px;
}

.status-circle.disputed {
    background-color: #ff4d4f;
}

.status-circle.active {
    background-color: #52c41a;
}

.status-circle.inactive {
    background-color: #faad14;
}

.status-circle.new,
.status-circle.pending {
    background-color: #52affa;
}

.status-circle.duplicate,
.status-circle.withdrawn,
.status-circle.end-dated {
    background-color: #d9d9d9;
}
