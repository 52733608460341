.impact-page {
    width: 100%;
}

.impact-metric-card {
    min-width: fit-content;
    padding: 0px !important;
    border: none !important;
}

@media all and (min-width: 1024px) {
    .impact-page {
        width: 1000px;
    }
}
