.sidebar {
    position: relative !important;
    min-height: 100vh;
    border-top: 1px solid #d9d9d9;
    background-color: #ffffff;
    transition: 0.3s all ease;
    border-right: 1px solid #d9d9d9;
}

.sidebar.collapsed {
    background-color: var(--ant-color-primary);
    color: #ffffff;
}

.sidebar .sidebar-header {
    padding: 8px 5px 8px 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    width: 100%;
    height: 40px;
}

.sidebar .sidebar-header.collapsed {
    padding: 8px 0;
}

.sidebar .results {
    font-weight: 400;
    font-size: 0.9em;
    color: #000000;
    word-break: unset;
}

.sidebar .collapse-trigger {
    position: relative;
    cursor: pointer;
    border-radius: 4px;
    color: #4a7088;
    text-align: center;
    font-weight: bold;
    padding: 0 5px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.sidebar .collapse-trigger:hover {
    background-color: rgba(0, 0, 0, 0.04);
}

.sidebar .sidebar-header.collapsed > .collapse-trigger {
    width: 100%;
    height: 40px;
    padding: 0;
    color: #ffffff;
}

.sidebar .collapse-trigger-filter-icon {
    font-size: 1.3em;
    height: 20px;
    width: 20px;
}

.sidebar .collapse-trigger-arrow-left,
.sidebar .collapse-trigger-arrow-right {
    height: 10px;
    width: 10px;
}

.sidebar .collapse-trigger-arrow-left {
    margin-right: 5px;
}

.sidebar .collapse-trigger-arrow-right {
    position: absolute;
    right: -13px;
    top: 14px;
    color: #4a7088;
}

.sidebar .resizer {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: 5px;
    cursor: ew-resize;
    background: transparent;
    transition: background-color 0.3s;
    z-index: 1;
}

.sidebar .resizer:hover {
    background: rgba(0, 0, 0, 0.1);
}

.sidebar .sidebar-footer {
    background-color: #fcfdfd;
    width: 100%;
    height: 50px;
    margin-top: auto;
    border-top: 1px solid #d6dbdb;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 5px;
}

.sidebar .sidebar-footer > .ant-btn-primary {
    background: var(--ant-color-primary);
}

.sidebar .sidebar-footer > .ant-btn-primary.inactive {
    background-color: #a0abab;
}

.sidebar .sidebar-footer > .ant-btn-primary:hover {
    background-color: #3b5a6d !important;
}

.sidebar .collapsed .sidebar-footer {
    display: none;
}

.sidebar .sidebar-tab .ant-tabs-tab {
    color: #000000; /* Default color black */
}

.sidebar .sidebar-tab .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: var(--ant-color-primary); /* Apply branding color when active */
}

.sidebar .sidebar-tab .ant-tabs-tab:hover {
    color: var(--ant-color-primary-hover); /* Apply branding hover color */
}

.sidebar .sidebar-tab .ant-tabs-ink-bar {
    background-color: var(--ant-color-primary); /* Apply branding color */
}
