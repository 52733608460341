.import-sites-table .ant-table-container {
    overflow-x: scroll;
}

.import-sites-table .row-with-error {
    background-color: rgba(195, 33, 65, 0.2);
}

.import-sites-table .row-with-warning {
    background-color: rgba(250, 150, 42, 0.2);
}

.import-sites-table .col-with-error {
    color: rgba(195, 33, 65, 0.9);
    font-weight: 700;
}

.import-sites-table .col-with-warning {
    color: rgba(250, 150, 42, 0.9);
    font-weight: 700;
}

.import-sites-progress {
    float: left;
    width: 300px;
    display: block;
}
